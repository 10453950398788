<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="pickupRequest/getListBySearch" :height="window.height - 280" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50" @download="download"  @clear="clearData" @edit="openModalForEdit">
          <template #title>
              {{ $t("Pickup Request") }}
          </template>
          <template #button>
            <b-button
                variant="primary" class="mr-1" v-if="userInfo.orgCode == 'org011'"
                @click="regularPickup">
                {{ $t('Regular Pick up') }}
              </b-button>
              <b-button
                variant="danger" class="mr-1"
                @click="downloadReport">
                {{ $t('Download Report') }}
              </b-button>
              <b-button variant="info" class="mr-1" @click="openModalForCreate">
                <feather-icon icon="PlusSquareIcon" class="mr-50" />
                <span class="align-middle">{{ $t("Create Request") }}</span>
              </b-button>
          </template>
          <template #head(bookingDatetime)="data">
              <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
                  {{ data.label }}
                  <template v-if="data.field.sortable">
                    <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondar': sortDesc || sortBy !== data.field.key }" />
                    <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondar': !sortDesc || sortBy !== data.field.key }" />
                  </template>
                  <flat-pickr class="form-control bg-white" v-model="bookingDatetime" @on-change="searchTime"  :config="{mode: 'range',dateFormat: 'Y-m-d H:i:ss' }" />
                </div>
          </template>
          <template v-slot:cell(bookingDatetime)='row'>
            {{ handleBookingDatetime(row.item.bookingDatetime)}}
          </template>
        </AdvanceTable>
    </section>
    <b-modal
      id="modal-info"
      centered
      title=""
      size="lg"
      hide-footer
      v-model="show"
      cancel-variant="outline-secondary"
      class="md-wd"
      no-close-on-backdrop
    >
      <b-row>
        <b-col lg="7">
          <b-card >
            <b-card-header>
              <b-card-title>{{ $t('Pick-Up Request Detail') }}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col lg="6">
                  <span class="pu-rd-p">Pick-Up #</span><br/>
                  <span class="pu-rd-sp">{{info.requestNo}}</span>
                </b-col>
                <b-col lg="6">
                  <span class="pu-rd-p">FOX Account#</span><br/>
                  <span class="pu-rd-sp">{{info.keAccountNumber}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <span class="pu-rd-p">Client Name</span><br/>
                  <span class="pu-rd-sp">{{info.displayName}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <span class="pu-rd-p">Company Phone</span><br/>
                  <span class="pu-rd-sp">{{info.orgPhone}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <span class="pu-rd-p">Contact Person</span><br/>
                  <span class="pu-rd-sp">{{info.contactName}}</span>
                </b-col>
                <b-col lg="6">
                  <span class="pu-rd-p">Contact Phone</span><br/>
                  <span class="pu-rd-sp">{{info.contactPhone}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <span class="pu-rd-p">Pick-Up Address</span><br/>
                  <span class="pu-rd-sp">{{info.pkAddress}}</span>
                  <feather-icon icon="CopyIcon" class="mr-50" @click="copyAddress(info.pkAddress)" style="float:right"/>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="4">
                  <span class="pu-rd-p">Quantity</span><br/>
                  <span class="pu-rd-sp">{{info.totalQty}}</span>
                </b-col>
                <b-col lg="4">
                  <span class="pu-rd-p">Total Weight</span><br/>
                  <span class="pu-rd-sp">{{info.totalWeight}} KG</span>
                </b-col>
                <b-col lg="4">
                  <span class="pu-rd-p">Remark</span><br/>
                  <span class="pu-rd-sp">{{info.remark}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <span class="pu-rd-p">Created By</span><br/>
                  <span class="pu-rd-sp">{{info.createdBy}}</span>
                </b-col>
                <b-col lg="6">
                  <span class="pu-rd-p">Sort Code</span><br/>
                  <span class="pu-rd-sp">{{info.route}}</span>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
<!--          <b-card >-->
<!--            <b-card-header>-->
<!--              <b-card-title>{{ $t('Action') }}</b-card-title>-->
<!--            </b-card-header>-->
<!--            <b-card-body>-->
<!--                <b-row >-->
<!--                <b-col lg="7">-->
<!--                    <v-select-->
<!--                    :options="areaList"-->
<!--                    label="routeArea"-->
<!--                    v-model="area"-->
<!--                    placeholder="Select Delivery Area"-->
<!--                    @input="getArea"-->
<!--                    >-->
<!--                    </v-select>-->
<!--                    <v-select-->
<!--                    :options="sortCodeList"-->
<!--                    label="sortingCode"-->
<!--                    v-model="sortCode"-->
<!--                    @input="getSortCode"-->
<!--                    placeholder="Select Sort Code"-->
<!--                    style="margin-top:10px;"-->
<!--                    >-->
<!--                    </v-select>-->
<!--                </b-col>-->
<!--                <b-col lg="12" style="margin-top:10px;display:flex;justify-content:flex-end">-->
<!--                    <b-button variant="success" :disabled="disabled" @click="saveFunc">Save</b-button>-->
<!--                    <b-button style="margin-left:10px" variant="danger" @click="cancelFunc">Cancel Request</b-button>-->
<!--                </b-col>-->
<!--                </b-row>-->
<!--            </b-card-body>-->
<!--          </b-card>-->
        </b-col>
        <b-col lg="5" >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('Milestone') }}</b-card-title>
            </b-card-header>
            <b-card-body>
              <div class="mt-cs">
                <feather-icon icon="UploadIcon" class="mt-ic" style="border:1px solid #787683;" />
                <div class="mt-ml" style="">
                  <p class="mt-p">
                    Service Party Received Request
                  </p>
                  <span class="mt-sp">{{info.uploadedTime}}</span>
                </div>
              </div>
              <div class="mt-cs">
                <feather-icon icon="UserCheckIcon" class="mt-ic"  style="border:1px solid #ECC19B;" />
                <div class="mt-ml">
                  <p class="mt-p">
                    Request Assigned
                  </p>
                  <span>{{info.assignedTime}}</span>
                </div>
              </div>
              <div class="mt-cs">
                <feather-icon icon="PackageIcon" class="mt-ic"  style="border:1px solid #7AD5A4;"/>
                <div class="mt-ml">
                  <p class="mt-p">
                    Courier Picked
                  </p>
                  <span>{{info.pickedTime}}</span>
                </div>
              </div>
              <div class="mt-cs" style="border-left:none">
                <feather-icon icon="XSquareIcon" class="mt-ic"  style="border:1px solid #F08C8C;"/>
                <div class="mt-ml">
                  <p class="mt-p">
                    Request Cancelled
                  </p>
                  <span>{{info.cancelledTime}}</span>
                </div>
              </div>
            </b-card-body>
          </b-card>
<!--          <b-card >-->
<!--            <b-card-header>-->
<!--              <b-card-title>{{ $t('Collected Item') }}</b-card-title>-->
<!--            </b-card-header>-->
<!--            <b-card-body>-->
<!--              <b-row v-for="(item,index) in info.items" :key="index" class="item-nth" style="border:1px solid #E8E7EA;border-bottom:none;padding:8px;font-size:14px;display:flex;justify-content:space-between">-->
<!--                  <span>{{item.itemNo}}</span>-->
<!--                    <b-badge-->
<!--                pill-->
<!--                class="ll-badge badge-tag">{{index+1}}</b-badge>-->
<!--                -->
<!--              </b-row>-->
<!--            </b-card-body>-->
<!--          </b-card>-->
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AdvanceTable from "@core/components/advanceTable/advanceTable.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import {
  BFormCheckbox, BButton, BBreadcrumb,
  BBreadcrumbItem, VBToggle, VBModal,
} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import permission from "@core/directives/permission";
export default {
  components: {
    ToastificationContent,
    AdvanceTable,
    BFormCheckbox,BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,flatPickr
  },
  directives: {
    permission,
  },
  data() {
    return {
      show:false,
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: 'requestNo', modelName: "requestNo",label: this.$t('Pickup Request ID'), filtertype: "input", sortable: true },
        { key: 'createdAt', modelName: "createdAt",label: this.$t('Pickup Request Creation Date'), filtertype: "datetime",sortable: true },
        { key: 'bookingDatetime', modelName: "bookingDatetime",label: this.$t('Pickup Request Pick Up Schedule'), sortable: true },
        { key: 'displayName', modelName: "displayName",label: this.$t('Pickup Request Company Name'), filtertype: "input",sortable: true },
        { key: 'keAccountNumber', modelName: "keAccountNumber",label: this.$t('Pickup Request FOX Account'), filtertype: "input",sortable: true },
        { key: 'totalQty', modelName: "totalQty",label: this.$t('Pickup Request Quantity'), filtertype: "input",sortable: true },
        { key: 'totalWeight', modelName: "totalWeight",label: this.$t('Pickup Request Total Weight (KG)'), filtertype: "input",sortable: true },
        { key: 'route', modelName: "route",label: this.$t('Pickup Request Route'), filtertype: "input",sortable: true },
        { key: 'createdBy', modelName: "userName",label: this.$t('Pickup Request Created By'), filtertype: "input",sortable: true },
        { key: 'status', modelName: "status",label: this.$t('Pickup Request Status'), filtertype: "select",options: { 1: "Uploaded", 2: "Assigned" ,3:"Picked",4:"Cancelled"},sortable: true },

      ],
      info:{},
      areaList:[{routeArea: "HKI"},
                {routeArea: "KLN"},
                {routeArea: "LTI"},
                {routeArea: "NT"}, 
                {routeArea: "OLI"}],
      sortCodeList:[],
      area:'',
      sortCode:'',
      bookingDatetime:'',
      sortBy: "",
      sortDesc: true,
      disabled:false,
      condition:{},
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
  },
  methods: {
    downloadReport() {
      let obj = {}
      obj.all = true
      obj.reportType = 'PickUpRequestReport'
      obj.from = '2022-03-07 00:00:00' // 日期不能为空，假数据
      obj.to = '2022-03-07 00:00:00'
      obj.paramJson = JSON.stringify(this.condition)
      this.$http.post('/exportReport/publish',obj).then(res=>{
        if (res.data.code == '200') {
          this.$router.push({ name: 'batch-update' })
        }
      })
    },
    cancelFunc(){
        this.$http.get('lmd/epod/cancel?bookingNo='+this.info.requestNo).then(res => {
            if (res.data.code == '200') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: res.data.message,
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
                this.show= false
            } 
        })
    },
    saveFunc(){
        if (!this.info.sortCode) {
            this.$message({type:'warning',message:'Please Select'})
            return false;
        }
        let data ={
            "requestNo":this.info.requestNo,
            "sortCode":this.info.sortCode
        }
        this.$http.post('lmd/epod/updateSortCode', data).then(res => {
            if (res.data.code == '200') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: res.data.message,
                    icon: 'CheckIcon',
                    variant: 'success',
                    },
                })
                this.show= false
            }
        })
    },
    copyAddress(context){
      navigator.clipboard.writeText(context).then(()=>{
        this.$message({type:'success',message:'Copy successfully'})
      })
    },
    searchTime(val){
        let date1  = val[0]?this.formatDate(val[0])+ ' 00:00:00':''
        let date2  = val[1]?this.formatDate(val[1])+ ' 23:59:59':''
        if (date1 != NaN &&date2 != NaN) {
            this.$refs.advanceTable.loadList({'startBookingDatetime':date1,'endBookingDatetime':date2});
        }
    },
    clearData(){
        this.bookingDatetime=''
    },
    getArea(s) {
      if (s) {
        this.area = s.routeArea
      }
      if (this.area !== null) {
        this.queryRouteCode()
        this.sortCode = null
      }
    },
    getSortCode(d){
      if (d) {
        this.info.sortCode = d.sortingCode
      }
    },
    queryRouteCode() {
      const data = {}
      data.routeArea = this.area
      this.sortCodeList = []
      this.$http.post('routeList/getDistrictAndSortCodeList', data).then(res => {
        const arr = res.data.data
        arr.map(i=>{
          this.sortCodeList.push({sortingCode: i.sortingCode})
        })
      })
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    regularPickup(){
      this.$router.push({ name: "regular-pickup"});
    },
    openModalForCreate() {
      this.$router.push({ name: "create-pickup"});
    },
    download(item){
      this.condition = item
    },
    openModalForEdit(item) {
        this.area=''
        this.sortCode=''
      this.$http.post('/pickupRequest/find',{id:item.id}).then(res => {
        this.show= true
        if (res) {
          this.info = res.data.data
          if (this.info.route == 'UNKNOWN' || !this.info.route) {
              this.disabled = false
          }else{
              this.disabled = true
          }
        }
      })
    },
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    },
    handleBookingDatetime(data) {
      if (!data) {
        return '';
      } else {
        let split = data.split(' ');
        if (split[1] == '07:00') {
          return split[0] + ' AM';
        } else if (split[1] == '14:00') {
          return split[0] + ' PM';
        } else {
          return split[0] + ' ANYTIME';
        }
      }
    }
  }
};
</script>
<style>
.md-wd .modal-dialog{
    width: 1000px !important;
}
</style>
<style scoped>
.badge-tag {
  background: #7367F0;
  color: #fff;
}
.mt-cs{
  display:flex;border-left: 1px solid #E8E7EA;margin-left: -1px;padding-left: 5px;position:relative;padding-bottom:20px
}
.mt-ml{
  margin-left:20px
}
.mt-ic{
  width:22px;height:22px;padding:3px;border-radius:100%;position:absolute;left:-11px;background:#fff
}
.mt-p{
  font-size: 14px;
}
.pu-rd-p{
  color: #F4A364;
  font-size: 14px;
}
.pu-rd-sp{
  font-size: 16px;
  line-height: 30px;
}
.item-nth:nth-child(1){
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.item-nth:nth-last-child(1){
    border-bottom-right-radius: 8px;
    border-bottom-left-radius:  8px;
    border-bottom:1px solid #E8E7EA !important;
}
</style>
